%button--reset {
  background: none;
  border: none;
  margin: 0;
  outline: none;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
}

%list--reset {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

// annoyingly importing here for override purposes...
@import "mixins";
@import "type";

%grid--2 {
  @extend %list--reset;
  display: grid;
  grid-gap: var(--pad-md);
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

%grid--4 {
  @extend %list--reset;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

%hover-tile {
  background-color: var(--lavendar);
  display: block;
  padding-bottom: 100%;
  position: relative;

  span {
    @extend %h2small;

    color: var(--white);
    display: block;
    left: 50%;
    opacity: 0;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s;

    &:after {
      background-color: var(--white);
      border-radius: 2px;
      bottom: -5px;
      content: "";
      height: 3px;
      left: -0.25rem;
      position: absolute;
      right: -0.25rem;
    }
  }

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    transition: opacity 0.3s;
    width: 100%;
  }

  &:hover {
    cursor: pointer;

    span {
      opacity: 1;
    }

    img {
      opacity: 0;
    }
  }
}
