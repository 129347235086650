@import "~scss/imports";

.graphic-download {
  @extend %button--border;
  display: inline-block;
  margin-top: var(--pad-lg);
  width: auto;

  span {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }

  svg {
    height: auto;
    margin-left: 10px;
    max-width: 25px;
    width: 100%;
  }
}

.graphic {
  text-align: center;
  canvas {
    width: 100%;
  }
}
