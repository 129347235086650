:root {

  // colors
  --purple: #1C1834;
  --green: #FF5554;
  --lavendar: #444BFD;
  --gray: #F5F3F1;
  --white: #ffffff;
  --dark-gray: #979797;

  // padding
  --pad-xs: 4px;
  --pad-sm: 8px;
  --pad: 21px;
  --pad-md: 32px;
  --pad-lg: 56px;
  --pad-xl: 100px;

  // breakpoints
  --break-xs: 350px;
  --break-sm: 580px;
  --break-md: 768px;
  --break-lg: 1024px;
  --break-xl: 1200px;
  --break-xxl: 1660px;

  --horiz-pad: 1rem;
  --vert-pad: 1.667rem;
  --maxWidth: 1600px;
  --maxBreakpoint: calc(var(--maxWidth) + 2.66rem);
  --navHeight: 100px;
  // --outerPadding: 1.5rem;

  --sans: "Work Sans", sans-serif;
  --condensed: "Oswald", sans-serif;
  --condenseBold: "Oswald", sans-serif;
  --bold: "Work Sans", sans-serif;
  --light: "Work Sans", sans-serif;
}
