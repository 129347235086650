@import "~scss/imports";

footer {
  background-color: var(--purple);
  color: var(--white);
  font-family: var(--condensed);
  font-weight: 700;
  padding-bottom: calc(3 * var(--horiz-pad));
  padding-top: calc(3 * var(--horiz-pad));

  padding-left: var(--pad-md);
  padding-right: var(--pad-md);

  .social-links {
    display: flex;
    font-size: 2rem;
    justify-content: space-between;
    margin-top: 1rem;
    max-width: 100%;
    width: 150px;

    svg {
      height: auto;
      width: 100%;
      max-width: 40px;
      * {
        fill: var(--white);
        transition: fill 0.2s;
      }
    }

    @media (min-width: 1200px) {
      font-size: 3rem;
      width: 220px;
    }

    @media (min-width: 1660px) {
      font-size: 3rem;
      width: 12vw;
    }
  }

  .copyright {
    text-transform: uppercase;
  }

  h2 {
    text-transform: uppercase;

    @extend %h2small;

    @media (min-width: 768px) {
      font-size: 2rem;
      margin-top: 0;
      span {
        display: block;
      }
    }
  }

  a {
    color: var(--white);
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.2s;

    &:hover {
      color: var(--green);

      svg * {
        fill: var(--green);
      }
    }
  }
  input {
    border-top: 1px solid var(--white);
    color: var(--white);
  }
  .btn {
    border: 1px solid var(--white);
  }
}

.footer-links {
  font-weight: 700;
  display: grid;
  grid-template-areas:
    "one"
    "two"
    "three";
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
  //   grid-template-areas:
  //     "one one"
  //     "two three";
  //   grid-template-columns: 1fr 1fr;
  // }

  // @media (min-width: 1024px) {
    grid-template-areas: "one two three";
    grid-template-columns: 1fr 1fr 1fr;
  }


  .footer-col {
    text-transform: uppercase;
    border-left: 1px solid var(--white);
    padding: 1rem var(--horiz-pad);
    display: flex;
    flex-direction: column;

    a {
      margin-bottom: 1rem;
      margin-right: 1rem;
    }

    &:first-child {
      border: none;
    }
  }
}
