@import "~scss/imports";

.color-tile {
  color: var(--purple);
  position: relative;

  h3 {
    @extend %h5;
    margin-bottom: 0.5rem;
  }
}

.color-tile__block {
  position: relative;

  @extend %hover-tile;

  span {
    color: var(--purple);
    &:after {
      background-color: var(--purple);
    }
  }

  &.white {
    span {
      color: var(--white);
      &:after {
        background-color: var(--white);
      }
    }
  }

  @media (max-width: 768px) {
    span {
      opacity: 1;
    }

    img {
      position: relative;
    }
  }
}

.color-tile__label {
  @extend %label;
  padding-right: 1rem;
}

.color-tile__value {
  @extend %p;
}
