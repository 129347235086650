@import "~scss/imports";

nav {
  background-color: var(--green);
  height: var(--navHeight);
  left: 0;
  padding: 21px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;

  &.hide-bg:not(.nav-open) {
    background-color: transparent;
  }

  &.nav-open {
    .header-menu {
      transform: translate(0);
      background-color: var(--purple);

      a {
        color: var(--white);

        &:hover {
          color: var(--green)
        }
      }

    }

    .toggle-menu {
      &:before,
      &:after {
        border-color: var(--white);
      }
      &:before {
        height: 27px;
        border-top: 0;
        transform: rotate(45deg) translate(7px, -6px);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.header-links {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 100%;

  a {
    display: block;
  }

  svg {
    height: auto;
    max-width: 105px;
    width: 100%;

    * { fill: var(--purple); }
  }
}

.header-menu {
  @extend %list--reset;
  background-color: var(--green);
  left: 0;
  padding: var(--navHeight) 0 50px;
  position: fixed;
  right: 0;
  top: 0;
  transition: transform 0.3s;
  transform: translateY(-100%);

  li {
    margin: 1rem 0;
    text-align: center;
  }

  a {
    @extend %h2small;
    color: var(--purple);
    line-height: 2;
    text-transform: uppercase;
    transition: color 0.2s;

    &:hover {
      color: var(--green);
    }
  }
}

.toggle-menu {
  @extend %button--reset;

  height: 27px;
  position: relative;
  width: 31px;
  z-index: 12;

  &:before,
  &:after {
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    transition: all 0.3s;
  }

  &:before {
    border-bottom: 4px solid var(--purple);
    border-top: 4px solid var(--purple);
    height: 7px;
    top: 0;
  }

  &:after {
    border-bottom: 4px solid var(--purple);
    bottom: 0;
  }
}
