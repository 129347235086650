@import "~scss/imports";

.home-hero {
  background-color: var(--green);
  color: var(--purple);
  text-align: center;

  header {
    max-width: 600px;
    margin: 0 auto;
    padding: var(--pad-lg);
  }

  h1 {
    @extend %h4;
    margin: 0 0 1rem;
  }

  p {
    @extend %p;
    font-family: var(--light);
    margin: 0;
  }

  svg {
    width: 100%;
    height: auto;
    max-width: 250px;
    margin: 1rem auto;
  }

  @media (min-width: 768px) {
    padding: var(--pad-lg);
    header {
      margin: var(--pad) auto 0;
    }
  }
}

.nav-grid {
  @extend %grid--4;

  @media (max-width: 768px) {
    grid-gap: var(--pad);
    padding: var(--pad);
  }
}

.nav-grid__tile {
  @extend %hover-tile;
  padding-bottom: 80%;

  span {
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;

    span {
      color: var(--purple);
      left: unset;
      opacity: 1;
      order: 1;
      position: relative;
      top: unset;
      transform: none;
      margin-top: var(--pad);
      white-space: normal;

      &:after {
        display: none;
      }
    }

    img {
      position: relative;
    }
  }
}
