@import "~scss/imports";

.grid-links {
  @extend %grid--2;
  padding: var(--pad) 0;

  @media (min-width: 768px) {
    padding: var(--pad-lg) 0;
  }
}

.grid-links--long {
  @extend %grid--4;
}
