@import "~scss/imports";

.tile-grid {
  @extend %grid--4;
  grid-gap: var(--pad);
  padding: var(--pad) 0;

  @media (min-width: 768px) {
    padding: var(--pad-lg) 0;
  }
}
