
%h1 {
  font-family: var(--condensed);
  font-size: 2.5em;
  font-weight: 700;
  text-transform: uppercase;
  // line-height: 4.2;

  @media (min-width: 768px) {
    font-size: 4.1em;
  }
}

%h1alt {
  font-family: var(--sans);
  font-size: 2.5em;
  font-weight: 700;
  // line-height: 4.2;

  @media (min-width: 768px) {
    font-size: 4.1em;
  }
}

%h2 {
  font-family: var(--condensed);
  font-size: 3em;
  font-weight: 700;
  line-height: 1.25;

  // @media (min-width: 768px) {}
}

%h2small {
  @extend %h2;
  font-size: 2rem;
  line-height: 1.1;
}

%h3 {
  font-family: var(--condensed);
  font-size: 1.2em;
  font-weight: 700;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 1.8em;
  }
}

%h4 {
  font-family: var(--sans);
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.4;
}

%h5 {
  font-family: var(--sans);
  font-size: 1.14em;
  line-height: 1.57;
  font-weight: 700;
}

%p {
  font-family: var(--sans);
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
}

%label {
  font-family: var(--condensed);
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

%button--border {
  @extend %button--reset;
  background-color: transparent;
  border: 4px solid var(--purple);
  color: var(--purple);
  font-family: var(--condensed);
  font-size: 32px;
  font-weight: 300;
  padding: 1rem 2rem;
  text-transform: uppercase;
  transition: all 0.2s;

  svg * {
    transition: fill 0.2s;
  }

  &:hover {
    border-color: var(--lavendar);
    background-color: var(--lavendar);
    color: var(--white);

    svg * {
      fill: var(--white);
    }
  }
}

%button--border--sm {
  @extend %button--border;
  font-size: 16px;
  padding: 0.75rem 1rem;

  &.selected,
  &:hover {
    background-color: var(--purple);
    border-color: var(--purple);
    color: var(--white);
  }

  @media (min-width: 768px) {
    padding: 0.75rem 2rem;
  }
}

%icon--link {
  align-items: center;
  color: var(--purple);
  display: flex;

  span {
    display: inline-block;
    position: relative;

    &:after {
      background-color: var(--purple);
      bottom: -2px;
      content: '';
      height: 2px;
      left: 0;
      opacity: 1;
      position: absolute;
      right: 0;
      transition: all 0.2s;
    }
  }

  svg {
    height: auto;
    margin-right: 10px;
    max-width: 20px;
    width: 100%;
  }

  &:hover {
    span:after {
      opacity: 0;
    }
  }
}

%inline-link {
  color: var(--green);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
