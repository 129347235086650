@import "~scss/imports";

.asset-builder {
  display: flex;
  flex-direction: column;
  padding: var(--pad);

  .graphic {
    margin-top: var(--pad-md);
  }

  .image-preview {
    height: auto;
    max-width: 250px;
    width: 100%;
    margin: var(--pad) 0;
  }

  @media (min-width: 768px) {
    display: grid;
    grid-gap: var(--pad-md);
    padding: var(--pad-lg) var(--pad-md);
    grid-gap: var(--pad-lg);
    grid-template-columns: 1fr 1fr;

    .graphic {
      margin-top: 0;
    }
  }
}

.asset-builder__label {
  @extend %h5;
  color: var(--purple);
  margin-top: var(--pad);
  margin-bottom: var(--pad-sm);


  @media (min-width: 768px) {
    margin-top: var(--pad-lg);

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.asset-builder__legal {
  @extend %p;
  font-family: var(--light);
  color: var(--dark-gray);
  margin-top: 0;
}

.asset-builder__form-label {
  font-family: var(--light)
}

.asset-builder__button {
  @extend %button--border--sm;
  margin-right: var(--pad-sm);
  margin-top: var(--pad-sm);

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.sm {
    font-size: 14px;
    padding: 0.5rem 1rem;
    margin-right: var(--pad-sm);
  }

  @media (min-width: 768px) {
    margin-right: var(--pad-md);
  }

  @media (min-width: 1024px) {
    margin-top: 0;
  }
}

.asset-builder input[type="text"] {
  background-color: var(--gray);
  border: 1px solid var(--purple);
  font-family: var(--light);
  margin-bottom: var(--pad);
  max-width: 250px;
  outline: none;
  padding: var(--pad);

  &::placeholder {
    opacity: 0.2;
  }

  &.asset-builder__textarea {
    max-width: none;
  }
}

.sizes-choices {
  display: grid;
  grid-template-columns: repeat(auto-fit,100px);
  grid-gap: 1rem;
}

.sizes-choices-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @extend %p;

}

.size-label {
  display: flex;
  flex-direction: column;
  height: 100px;
  margin: 0 auto;
  // padding: 5px;
  width: 100px;
}

.size-label-image {
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

input[type="radio"] {
  display: block;
  appearance: none;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  opacity: 0;

  +* {
    border: 2px solid transparent;
  }
}

input[type="radio"]:not(:checked):hover + *,
input[type="radio"]:focus + * {
  border-color: var(--purple);
  cursor: pointer;
}

input[type="radio"]:checked + * {
  border-color: var(--purple);
}

input[type="radio"]:checked + .size-label-image {
  border-color: var(--purple);

  rect {
    fill: var(--gray);
  }
}

.asset-builder__size {
  all: unset;
  // @extend %inline-link;

  > div {
    background-color: var(--gray);
  }
}
