@import "~scss/imports";

.grid-item {
  display: grid;
  grid-template-rows: 1fr auto;
  text-align: center;
  transition: color 0.2s;

  &:hover {
    span {
      opacity: 1;
    }

    img {
      opacity: 0;
    }
  }

  p {
    @extend %h5;
    color: var(--purple);
    margin-bottom: var(--pad-sm);
    transition: color 0.2s;
  }

  > span {
    @extend %p;
    color: var(--dark-gray);
  }
}

.grid-item__hover {
  @extend %hover-tile;
}
