@import "~scss/imports";

.asset-tile {
  color: var(--purple);

  &:hover {
    span {
      opacity: 1;
    }

    img {
      opacity: 0;
    }
  }

  p {
    @extend %p;
    font-family: var(--light);
    margin: 1rem 0;
  }

  strong {
    text-transform: uppercase;
  }
}

.asset-tile__hover {
  @extend %hover-tile;
}
