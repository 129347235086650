@import "~scss/imports";

.page-intro {
  padding: var(--pad);

  p, div {
    @extend %h5;
  }

  h1 {
    @extend %h1;
    margin: var(--pad-md) 0;
  }

  .back-link {
    @extend %icon--link;

    svg {
      transform: rotate(90deg);
    }
  }

  @media (min-width: 768px) {
    padding: var(--pad-md);
    width: 66%;

    .back-link {
      margin-top: var(--pad-lg);
    }
  }

  @media (min-width: 1024px) {
    max-width: 900px;
  }
}

.page {
  padding: var(--pad-md);
  min-height: 50vh;
}

.page-error {
  text-align: center;

  h1 {
    @extend %h1;
  }

  a {
    @extend %inline-link;
  }
}

.page-load {
  min-height: 50vh;
  text-orientation: center;

  h2 {
    font-family: var(--sans);
  }
}
