@import "~scss/imports";

.document-tile {
  border: 1px solid transparent;
  display: block;
  padding: var(--pad-sm);
  transition: border 0.2s;

  &:hover {
    border-color: var(--lavendar);
  }
}

.document-tile__link {
  @extend %hover-tile;
  padding-bottom: 150%;
}

.document-tile__name {
  @extend %h5;
  text-align: center;
}
