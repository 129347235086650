// @import "default";
// @import "color";
@import "fonts";
@import "variables";
@import "mixins";
// @import "container";

  // --break-xs: 350px;
  // --break-sm: 580px;
  // --break-md: 768px;
  // --break-lg: 1024px;
  // --break-xl: 1200px;
  // --break-xxl: 1660px;


html,
body {
  font-size: 14px;
  font-family: var(--sans), sans-serif;
  color: var(--purple);
  margin: 0;

  * {
    box-sizing: border-box;
  }

  @media (min-width: 768px) {
    --horiz-pad: 1.667rem;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
  }
  @media (min-width: 1200px) {
    font-size: 18px;
    --navHeight: 95px;
  }
  @media (min-width: 1660px) {
    font-size: 1.05vw;
    --navHeight: 100px;
  }
}
h1 {
  margin-bottom: 3rem;
}
p {
  font-weight: 200;
  font-size: 1.15rem;
  line-height: 1.6;
}

a {
  text-decoration: none;
}

main {
  margin-top: var(--navHeight);
}

input::placeholder,
textarea::placeholder {
  color: var(--purple);
}

.max-width {
  margin: 0 auto;
  max-width: var(--maxWidth);
}

.banner-wrap {
  position: relative;
}
.banner {
  figcaption {
    position: absolute;
    right: var(--horiz-pad);
    z-index: 10;
    @media (min-width: 768px) {
    }
  }
  .gatsby-image-wrapper:before,
  .gatsby-image-wrapper:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .gatsby-image-wrapper:before {
    background-image: linear-gradient(
      180deg,
      rgba(103, 10, 133, 0.09) 41%,
      rgba(103, 10, 133, 0.39) 78%
    );
  }
  .gatsby-image-wrapper:after {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
.grid-outer.first-block {
  @media (max-width: 767px) {
    margin-top: -27.6rem;
    padding-top: 20rem;
  }
}
.banner-text {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  z-index: 10;
  h1 {
    font-size: 2.667rem;
    line-height: 1.2;
  }
  .heading {
    width: 100%;
  }
  p {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .ved-date {
    display: block;
    font-size: 1.3rem;
    margin-top: 1rem;
    @media (min-width: 768px) {
      font-size: inherit;
      margin-top: 0;
    }
  }
  .grid-outer {
    padding: 0 1rem 0 0;
  }
  h1 {
    color: var(--white);
  }
  @media (max-width: 767px) {
    margin-top: -12.4rem;
    .split {
      // .large {
      //   margin-top: 2rem;
      // }
      padding: 0;
      .inner-content,
      .heading {
        padding: 0 1rem;
        p {
          margin: 0;
        }
      }
      .inner-content {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
    .inner-content {
      // border-left: 1px solid var(--purple);
      // border-right: 1px solid var(--purple);
    }
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 768px) {
    .grid-outer {
      padding: 1rem;
    }
    .split {
      padding: 0;
    }
  }
  @media (min-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    color: var(--white);
    margin-top: 0;
  }
}
.grid-outer {
  padding: 0 1rem;
  position: relative;
  overflow: hidden;
  & > h1 {
    padding: 0 var(--horiz-pad);
  }
  &:before,
  &:after {
    content: "";
    height: 100%;
    display: block;
    position: absolute;
    width: 1rem;
    left: 0;
    top: 0;
    border-right: 1px solid var(--purple);
    z-index: 11;
  }
  &:after {
    left: auto;
    right: 0;
    border-right: none;
    border-left: 1px solid var(--purple);
  }
  &.green-bar:before {
    background-color: var(--green);
    border-right: 1px solid var(--green);
  }
  &:not(.banner).green-bar:after {
    background-color: var(--green);
    border-left: 1px solid var(--green);
  }
  &.purple-status-bar:before {
    background-color: var(--lavendar);
  }
  &.purple {
    background-color: var(--purple);
    color: var(--white);
    &:after,
    &:before {
      background-color: var(--purple);
      border-color: var(--white);
    }
  }
  @media (min-width: 1024px) {
    padding: 0 1.33rem;
    &:before,
    &:after {
      width: 1.33rem;
    }
  }
  @media (min-width: 1660px) {
    padding: 0 var(--outerPadding);
    &:before,
    &:after {
      width: var(--outerPadding);
    }
  }
}
.banner {
  .gatsby-image-wrapper {
    height: calc(100vh - 200px);
  }
  @media (min-width: 768px) {
    height: 100vh;

    .gatsby-image-wrapper {
      height: 100%;
      width: auto;
    }
  }
  @media (min-width: 768px) and (orientation: portrait) {
    max-height: 1000px;
  }
}
.grid-outer.banner {
  overflow: visible;
  margin-top: calc(-1 * var(--navHeight));
  padding: 0 0 0 1rem;
  &:after {
    border-left: none;
  }
  .split {
    padding-bottom: 3.75rem;
  }
  .inner-content {
    border: none !important;
  }
  .grid-outer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .grid-outer:after,
  .grid-outer:before {
    border: none;
  }
  @media (min-width: 768px) {
    .split {
      padding-bottom: 0;
    }
  }
  @media (min-width: 1024px) {
    padding-left: 1.33rem;
  }
  @media (min-width: 1660px) {
    padding-left: var(--outerPadding);
  }
}
h1 {
  font-size: 1.48rem;
  font-weight: 800;
  line-height: 1.1;
  @media (min-width: 768px) {
    font-size: 2rem;
  }
  @media (min-width: 1200px) {
    font-size: 2.667rem;
  }
}
h2 {
  font-size: 1.77rem;
  line-height: 1.55;
  font-weight: 500;
}
.image-block {
  position: relative;
  .gatsby-image-wrapper {
    width: 100%;
  }
  figcaption {
    position: absolute;
    bottom: 0;
    left: 1px;
    justify-content: flex-start;
    // background-color: var(--white);
    padding: 1rem;
    margin: 0;
    // font-size: 0.88rem;
  }
  @media (min-width: 768px) {
    figcaption {
      position: relative;
    }
  }
}
figcaption {
  background-color: var(--white);
  margin: 0;
  font-size: 0.88rem;
  justify-content: flex-end;
  font-weight: 200;
}
.ved-grid {
  display: grid;
  grid-template-areas:
    "one"
    "two"
    "three";
  &.grid-alt {
    grid-template-areas:
      "two"
      "one";
  }
  grid-template-columns: 1fr;
  // grid-gap: 1rem;
  //margin: 4rem 0 0;
  & + .ved-grid {
    margin-top: 0;
  }
  .vertical-spacer {
    height: 8rem;
  }
  @media (min-width: 768px) {
    grid-template-areas: "two three";
    grid-template-columns: 1fr 1fr;
    figcaption {
      margin-bottom: 6rem;
    }
    &.grid-alt {
      grid-template-areas: "one two";
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (min-width: 1024px) {
    &,
    &.grid-alt {
      grid-template-areas: "one two three";
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
.col1 {
  grid-area: one;
}
.col2 {
  grid-area: two;
}

@media (min-width: 1024px) {
  .overflow {
    max-width: 133%;
  }

  .image-block.overflow-left .gatsby-image-wrapper {
    width: 133%;
    margin-left: -33%;
  }
  .image-block.overflow-right .gatsby-image-wrapper {
    width: 133%;
    margin-right: -33%;
    max-width: 133%;
  }
}
figcaption {
  display: flex;
  flex-direction: row;
  margin: 1rem 1rem 0;
  line-height: 1.5;
  span {
    display: inline-block;
  }
  &:before {
    display: inline-block;
    content: "—";
    margin-right: 0.5rem;
  }
}
.col3 {
  grid-area: three;
}
.col2,
.col3 {
  @media (min-width: 1024px) {
    border-left: 1px solid var(--purple);
  }
}

.grid-alt .col2.image img {
  @media (min-width: 768px) {
    border-left: 1px solid var(--purple);
  }
}
.col3 {
  @media (min-width: 768px) {
    border-left: 1px solid var(--purple);
  }
}
.col {
  position: relative;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    margin-bottom: 0;
    // padding: 0 var(--horiz-pad);
    margin: 1rem var(--horiz-pad);
    & + p {
      margin-top: 1rem;
    }
  }
  p {
    padding: 0 var(--horiz-pad);
  }
  @media (min-width: 768px) {
    h1 {
      margin-bottom: 0;
      // padding: 0 var(--horiz-pad);
      margin: 0 var(--horiz-pad);
    }
  }
}
.col.col1.no-border,
.col.col2.no-border,
.col.col3.no-border {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="submit"],
textarea {
  font-family: var(--sans);
  font-weight: 200;
  background-color: transparent;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--purple);
  border-left: 1px solid var(--purple);
  font-size: 1em;
  color: var(--purple);
  footer & {
    border-color: var(--white);
    padding: 2.5em var(--horiz-pad);
  }
}
.international_link-wrap {
  display: inline-block;
  padding: 1rem 0;
}
.country_drop_wrap {
  display: inline-block;
  border-bottom: 1px solid var(--purple);
  padding: 0.5rem 0;
  margin-left: 1rem;
}
#can_embed_form_inner {
  h2,
  h4 {
    display: none;
  }
}

input[type="submit"] {
  background: var(--purple);
  color: var(--white);
  border: none;
  .split & {
    width: 50%;
  }
}
.ajax-loading {
  display: none;
}
#form_col2 {
  margin-top: 4rem;
  border: none;
  footer & {
    margin-top: 0;
  }
}
.action_owner,
#logo_wrap {
  display: none;
}


/* Tiles */
.tiles {
  display: grid;
  grid-template-columns: 1fr;
  border-top: 1px solid var(--purple);
  h2 {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 4rem;
  }
  .tile {
    color: var(--purple);
    border-bottom: 1px solid var(--purple);
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    &:focus {
      outline: thin dotted;
    }
    &:hover {
      opacity: 0.8;
    }
    @media (min-width: 768px) {
      border-left: 1px solid var(--purple);
    }
    span {
      @extend h2;
    }
    a {
      text-decoration: none;
      color: var(--purple);
    }
    .link-text {
      display: inline-block;
      text-transform: uppercase;
      font-size: 0.77rem;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid var(--green);
      font-weight: 600;
    }

    h2 {
      padding-right: 4.2rem;
      line-height: 1.23;
    }
    svg {
      position: absolute;
      right: var(--horiz-pad);
    }

    .tile-inner {
      margin-left: 1rem;
      h2:first-child {
        margin-top: 0;
      }
    }
    .more-btn {
      font-size: 0.7778rem;
      font-family: var(--sans);
      font-weight: 200;
      text-transform: uppercase;
      background-color: transparent;
      border: none;
      color: var(--purple);
      padding: 0;
      width: 100%;
      text-align: left;
      &:focus {
        outline: thin dotted;
      }

      svg {
        width: 20px;
        height: auto;
        margin-left: 0.35rem;
      }
      &.open svg {
        transform: rotate(180deg);
      }
    }

    .more-info {
      margin-top: 1rem;
      p {
        font-size: 1rem;
        line-height: 1.555;
      }
    }
    svg {
      width: 4rem;
      height: auto;
    }
    padding: calc(2 * var(--horiz-pad)) var(--horiz-pad);
    &:nth-child(3n + 1) {
      border-left: none;
    }
  }
  &.ways-to-vote {
    border: none;
    border-top: 1px solid var(--purple);
    .tile {
      border: none;
      border-bottom: 1px solid var(--purple);
      flex-direction: row;
      align-items: flex-start;
      h2 {
        padding-right: 0;
      }
      span {
        padding-right: 0;
      }
      .more-btn svg {
        right: auto;
        position: relative;
      }
    }
    @media (min-width: 768px) {
      border-top: none;
      .tile {
        border-left: 1px solid var(--purple);
        border-bottom: none;
        &:nth-child(3n + 1) {
          border-left: none;
        }
      }
    }
  }
}
.tile {
  @media (max-width: 767px) {
    &.empty {
      display: none;
    }
    &.open {
      background-color: var(--white);
      &:before {
        content: "";
        position: absolute;
        width: var(--outerPadding);
        background-color: var(--lavendar);
        left: calc(-1 * (var(--outerPadding) + 1px));
        top: -1px;
        height: calc(100% + 2px);
      }
    }
  }
}

/* Map */

.map-wrap {
  display: flex;
  flex-direction: row;
  margin: 4rem 0;
  // padding: 0 var(--horiz-pad);
  display: grid;
  grid-template-areas:
    "heading"
    "map"
    "legend";
  grid-gap: 1rem;

  .map-holder {
    padding: 1rem;
  }
  .map-heading {
    grid-area: heading;
    h1 {
      margin-bottom: 0;
    }
  }
  .map-holder {
    grid-area: map;
  }
  .legend {
    grid-area: legend;

    display: flex;
    flex-direction: row;
  }
  svg {
    width: 100%;
    height: auto;
  }
  .map-heading,
  .legend-section {
    padding: 0 var(--horiz-pad);
  }
  @media (min-width: 768px) {
    margin: 8rem 0;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: minmax(300px, auto) auto;
    grid-template-areas:
      "map heading"
      "map legend";
    h1 {
      margin-top: 0;
    }
    grid-gap: 0;
    .legend {
      flex-direction: column;
    }
    .map-heading,
    .legend {
      border-left: 1px solid var(--purple);
    }
  }
  @media (min-width: 1200px) {
    grid-template-rows: minmax(300px, auto) auto;
  }
  @media (min-width: 1660px) {
    grid-template-rows: minmax(20vw, auto) auto;
  }
}

.legend-section {
  &:first-child {
    border: none;
  }
}
.legend-element {
  display: flex;
  flex-direction: row;
  // display: grid;
  // grid-template-columns: 2.22rem auto;
  grid-gap: 1rem;
  justify-content: space-between;
  padding: 1rem 0;
  align-items: flex-start;
  .color-block {
    width: 2.22rem;
    height: 2.22rem;
    display: inline-block;
  }
  .description {
    width: calc(100% - 3rem);
  }
}

/* Button */
.btn {
  background: var(--purple);
  color: var(--white);
  border: none;
  width: 100%;
  padding: 1.5rem;
  transition: 0.5s;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    color: var(--purple);
    background: var(--green);
  }
  span {
    display: inline-block;
    font-family: var(--sans);
    font-weight: 400;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    // border-bottom: 10px solid var(--green);
  }
  &:focus {
    outline: thin dotted;
  }
  &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
  @media (min-width: 768px) {
    padding: 2rem 3rem;
  }
}

/* Page Header */
#PageHeader {
  // & + .grid-outer {
  //   padding-top: 4rem;
  // }
  // @media (min-width: 768px) {
  //   & + .grid-outer {
  //     padding-top: 6rem;
  //   }
  // }
}
.page-header {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  .header-image {
    order: 1;
  }
  .header-text {
    padding: 0 var(--horiz-pad) 3rem;
    order: 2;
    h1 {
      font-size: 2.66rem;
    }

    p {
      font-weight: 400;
    }
    @media (min-width: 1200px) {
      h1 {
        font-size: 4rem;
      }
    }
  }

  @media (min-width: 768px) {
    .btn {
      width: auto;
      background: var(--white);
      color: var(--purple);
      padding: 0;
      position: absolute;
      bottom: 0;
      span {
        padding-bottom: 0.5rem;
        border-bottom: 1.5px solid var(--green);
      }
    }
  }
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 2fr;
    .header-text {
      order: 1;
    }
  }
}

/* NAV */
.lines {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2.1rem;
  height: 1.5rem;
  position: relative;

  .line {
    width: 100%;
    height: 1px;
    background-color: var(--white);
    transition: 0.3s;
  }
  .line:first-child {
    transform-origin: 0 0;
  }
  .line:last-child {
    transform-origin: 0 100%;
  }
  @media (min-width: 768px) {
    display: none;
  }
}
nav a + a {
  margin-left: 1rem;
}
// nav {
//   display: grid;
//   grid-template-columns: 100px auto;
//   padding: 0.8rem 0;
//   // max-width: var(--maxWidth);
//   margin: 0 1rem;
//   h1 {
//     margin: 0 0 0 var(--horiz-pad);
//     line-height: 0;

//     svg {
//       height: 6rem;
//       width: auto;
//       transition: 0.5s;

//       .show-bg & {
//         height: 3.75rem;
//       }
//       @media (min-width: 1660px) {
//         height: 100px;
//         .show-bg & {
//           height: 70px;
//         }
//       }
//     }
//   }

//   @media (min-width: 768px) {
//     margin: 0 1.33rem;
//   }

//   @media (min-width: 1024px) {
//     margin: 0 var(--outerPadding);
//   }
// }

.nav-links {
  display: flex;
  justify-content: flex-end;
  font-family: var(--condensed);
  a {
    color: var(--white);
    text-decoration: none;
    align-items: center;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 1.5rem;
    transition: 0.5s;
    .nav-item {
      border-left: 1px solid var(--white);
      padding: 0 1rem 1.25rem;
    }

    &.active,
    &:hover {
      color: var(--green);
      .nav-item {
        border-color: var(--green);
      }
    }
    @media (min-width: 768px) {
      font-size: 1rem;
      span {
        display: block;
      }
    }
    @media (min-width: 1660px) {
      font-size: 22px;
    }
  }
}

// @media (max-width: 767px) {
//   nav {
//     display: grid;
//     grid-template-columns: 100px auto;
//     justify-content: space-between;
//     padding: 0;
//     margin: 0;
//     z-index: 20;
//     .lines {
//       margin: 2rem 0.75rem;
//     }
//     .nav-links {
//       display: none;
//       max-height: 0;
//       overflow: hidden;
//     }
//     h1 {
//       margin: 1rem 1rem 1rem var(--horiz-pad);
//       svg {
//         transition: 0.25s;
//       }
//     }
//   }
//   &.show-bg nav {
//     h1 {
//       svg {
//         width: auto;
//         height: 50px;
//       }
//     }
//   }
//   &.nav-open {
//     background-color: var(--purple);
//     height: 100vh;
//     .nav-links {
//       display: flex;
//       transition: 0.25s;
//       max-height: 100vh;
//     }
//     .lines {
//       z-index: 11;
//       position: sticky;
//       top: 0;
//       right: 0;

//       .line {
//         position: absolute;
//         top: 0;
//         left: 0;
//         // top: 50%;
//         // left: 50%;
//       }
//       .line:nth-child(2) {
//         opacity: 0;
//       }
//       .line:first-child {
//         transform: rotate(45deg);
//       }
//       .line:last-child {
//         top: auto;
//         bottom: 0;
//         transform: rotate(-45deg);
//       }
//     }

//     h1 {
//       z-index: 11;

//       top: 0;
//       left: 0;
//       margin: 1rem 1rem 1rem var(--horiz-pad);
//       svg {
//         width: auto;
//         height: 50px;
//       }
//     }
//     .nav-links {
//       /*mobile*/
//       position: sticky;

//       left: 0;
//       height: calc(100vh - 6rem);
//       width: 100vw;
//       background: var(--purple);
//       z-index: 15;
//       flex-direction: column;
//       justify-content: flex-start;
//       border-top: 1px solid var(--white);
//       a {
//         height: 33.34%;
//         width: calc(100% - 2rem);
//         margin-left: 0;
//         border: none;
//         display: flex;
//         justify-content: center;
//         align-content: center;
//         white-space: nowrap;
//         position: relative;
//         margin: 0 1rem;
//         // border-left: 1px solid var(--white);
//         // border-right: 1px solid var(--white);
//         .nav-item {
//           border: 0;
//         }
//         &:after {
//           position: absolute;
//           content: "";
//           height: 5px;
//           width: 65px;
//           background-color: var(--green);
//           margin-top: 2rem;
//         }
//       }
//       a + a {
//         border-top: 1px solid var(--white);
//       }
//     }
//   }
// }

.grid-outer > .heading {
  margin-top: 4rem;
  @media (min-width: 1024px) {
    margin-top: 8rem;
  }
}
/* heading */
.heading {
  display: block;
  margin-left: var(--horiz-pad);
  margin-right: var(--horiz-pad);
  width: auto;

  @media (min-width: 1024px) {
    h1 {
      width: 66.66%;
    }
  }
}
.partners {
  padding: 2.5rem 0;
  @media (min-width: 768px) {
    padding: 4rem 0 8rem;
  }
}
/* Split */
.split-block {
  padding: 2.5rem 0;
  @media (min-width: 768px) {
    padding: 8rem 0;
  }
  .pad-adj-bottom & {
    padding-bottom: 2rem;
    @media (min-width: 768px) {
      padding: 2rem 0 2rem;
      h1 {
        margin-bottom: 1rem;
      }
      aside {
        padding-bottom: 1rem;
        font-weight: 200;
      }
      .inner-content {
        margin-bottom: 0;
      }
    }
  }
  .pad-adj-top & {
    padding-top: 0;
  }
}
.partners {
  h1 {
    margin: 0 var(--horiz-pad);
  }
}
.split {
  // display: grid;
  // grid-template-areas:
  //   "small large large"
  //   "small large large";

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 1rem;
  div {
    h1 {
      margin-top: 0;
    }
  }
  .btn span {
    max-width: calc(100% - 2rem);
  }
  .inner-content {
    p,
    h1,
    h2 {
      margin-top: 0;
    }
  }
  @media (max-width: 767px) {
    .large {
      order: 1;
    }
    .small {
      order: 2;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 0;
    .inner-content {
      padding: 0 var(--horiz-pad);
      margin-bottom: 1.5rem;
    }
    .small:nth-of-type(2) .inner-content,
    .large:only-child .inner-content {
      border-left: 1px solid var(--purple);
    }
    .small:nth-of-type(1) .inner-content {
      border-right: 1px solid var(--purple);
    }
    .large:only-child {
      margin-left: 33.33%;
    }
  }
  .btn {
    width: 100%;
    margin-top: 0;
    border-left: none;
    padding-left: 0;
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-decoration: none;
  }
  .inner-content + .btn {
    margin-top: 2.5rem;
  }
}
.large {
  // grid-area: large;
  grid-column: span 2;
  width: 100%;

  @media (min-width: 768px) {
    width: 66.666%;
  }
  @media (min-width: 1024px) {
    .btn {
      width: 50%;
    }
  }
}
.small {
  grid-row: 1;
  grid-column: span 1;
  width: 100%;
  @media (min-width: 768px) {
    width: 33.33%;
  }
}

.subheading {
  font-size: 0.888rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  display: block;
}

/* FAQ */
.faqItem {
  margin-bottom: 2rem;
  .question {
    font-weight: 800;
    font-size: 1.15rem;
    margin: var(--horiz-pad);
    margin-left: 0;
    cursor: pointer;
    display: flex;
    align-self: center;
    justify-content: space-between;
    transition: 0.5s;
    svg {
      width: 25px;
      height: auto;
      margin-left: 1rem;
    }
    &.open svg {
      transform: rotate(180deg);
    }

    &:hover {
      color: var(--green);
    }
  }
  .answer {
    line-height: 1.55;
    margin-bottom: 2.5rem;
    font-weight: 200;
  }
  @media (min-width: 768px) {
    padding-right: var(--horiz-pad);
    .question {
      font-size: 1.333rem;
      justify-content: flex-start;
    }
  }
}

/* Voting Sites */
.select-wrap {
  display: block;
  position: relative;
  z-index: 10;
  &:hover .select {
    display: flex;
  }
}
.select {
  display: block;
  background-color: var(--purple);
  color: var(--white);
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  display: none;
  flex-direction: column;
}

.site-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  .dropdown-wrap {
    flex-grow: 1;
  }
  .Dropdown-root {
  }
  label {
    margin: 0 1rem 0 0;
  }
  @media (min-width: 768px) {
    label {
      margin: 0 1rem 0 var(--horiz-pad);
    }
    padding: 0 2rem;
  }
}

.split {
  .Dropdown-control {
    border: 0;
    border-bottom: 1px solid var(--purple);
    color: var(--purple);
  }
  .Dropdown-option {
    color: var(--purple);
  }
  .Dropdown-option:hover {
    background-color: var(--purple);
    color: var(--white);
  }
  .Dropdown-option.is-selected {
    background-color: var(--lavendar);
    color: var(--purple);
  }
}

/* About */
.ved-about-grid {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;

  .text-block {
    padding: 1rem;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    div {
      width: 66.66%;
      order: 1;
      z-index: 1;
      position: relative;
    }
    .text-block {
      padding: var(--horiz-pad);
      z-index: 10;
    }
    &.image-right {
      .image-block {
        margin-left: 33.33%;
        margin-bottom: -15%;
        figcaption {
          margin-left: calc(50% + 1rem);
        }
      }
      .text-block {
        background-color: var(--white);
      }
    }
    &.image-left {
      .text-block {
        background-color: var(--white);
        margin-left: 33.33%;
        margin-bottom: -10%;
      }
      .image-block {
        order: 2;
      }
    }
  }
  @media (min-width: 1024px) {
    margin-top: 8rem;
    &.image-left .text-block {
      padding-top: 0;
      h1 {
        margin-top: 0;
      }
    }
  }
  @media (min-width: 768px) and (orientation: portrait) {
    &.image-right {
      .image-block {
        margin-bottom: -20%;
      }
    }
  }
}
.column-list {
  margin-top: 2rem;
}
.column-list ul {
  width: 100%;
  column-rule: 1px solid var(--purple);
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  li {
    flex-basis: 50%;
    list-style: none;
    margin: 0;
    padding: 0 1rem 2rem;
  }
  .name {
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    line-height: 1;
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    .isk {
      // margin-left: 1rem;
      // position: absolute;
      // right: -1.5rem;
      // top: 0;
      margin-left: 1rem;
      transform: translate(0, 0.5rem);
    }
  }
  .org {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 200;
  }
  @media (min-width: 768px) {
    li {
      flex-basis: 33.33%;
    }
    .col2 {
      border-left: 1px solid var(--purple);
    }
    .name {
      font-size: 1.33rem;
    }
  }
}

.isk {
  font-size: 1.5rem;
  color: var(--green);
  font-weight: 700;
}

aside {
  position: relative;
  margin-left: 2rem;
  font-size: 1rem;
  .isk {
    position: absolute;
    left: -1.5rem;
    top: -0rem;
  }
  @media (min-width: 768px) {
    font-size: 1.33rem;
    .isk {
      left: -1.25rem;
      top: 0.25rem;
    }
  }
}

/* select 2 overrides */
.select2-search {
  display: none;
}
.select2-container {
  background: transparent;
  padding: 1rem;

  a.select2-choice {
    border: 1px solid var(--purple);
    border-radius: 0;
    border: none;
    background: transparent;
    .select2-arrow {
      background: transparent;
    }
  }
}

.embed-form-wrap {
  padding-top: 2.5rem;
}
.update-date {
  display: block;
  margin: 0.5rem 0 2rem;
  font-weight: 200;
}
.modal {
  position: fixed;
  background: rgba(100, 100, 100, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  top: 0;
  left: 0;
  .modal__inner {
    padding: 2rem 1.5rem;
    width: 80%;
    max-width: 745px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    z-index: 10000;
    border-left: 25px solid var(--green);
    h1,
    p {
      margin-bottom: 2rem;
    }
    .btn {
      display: inline-block;
      width: auto;
    }
    .close-x {
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
    }
  }
}
