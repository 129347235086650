@import "~scss/imports";

.fonts {
  @extend %list--reset;

  h5 {
    @extend %h4;
    grid-area: label;
    text-transform: uppercase;
  }

  p {
    grid-area: copy;
  }
}

.flex-center {
  align-items: center;
  display: flex;
  margin: 0;
}

.fonts-row {
  border-top: 2px solid var(--purple);
  display: grid;
  grid-gap: var(--pad-md);
  grid-template-areas:
    "label"
    "copy"
    "table"
  ;
  padding: var(--pad-md);

  &:last-of-type {
    border-bottom: 2px solid var(--purple);
  }

  @media (min-width: 768px) {
    padding: var(--pad-lg) var(--pad-md);
    // grid-gap: var(--pad-sm);
    grid-template-areas:
      "label label"
      "copy table"
    ;
  }

  @media (min-width: 1024px) {
    grid-gap: var(--pad-lg);
    grid-template-columns: 1fr 4fr 2fr;
    grid-template-areas:
      "label copy table"
    ;
  }
}

.font__table {
  @extend %list--reset;

  align-self: center;
  grid-area: table;
  height: max-content;

  li {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
}

.font__label {
  @extend %label;
  padding-right: 1rem;
}

.font__value {
  @extend %p;
}
