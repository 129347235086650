@import "~scss/imports";

.color-selector {
  border: 1px solid var(--white);
  border-radius: 50%;
  margin-right: var(--pad-sm);
  padding: 3px;

  &:hover,
  &.selected {
    border: 1px solid var(--purple);
  }

  button {
    @extend %button--reset;
    @extend %h5;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
}

.color-combination {
  display: flex;
}
